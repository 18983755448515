import { Skeleton } from '@mui/material';
import React, { useState, useEffect } from 'react';

const Skeletonloader = ({ variant, height, width, borderRadius,className,marginLeft,objectFit,marginTop}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [screenWidth]); 
  const getResponsiveWidth = () => {
    if (typeof width === 'string') {
      return width; 
    } else if (typeof width === 'object') {
      
      if (screenWidth >= 1332 ) {
        return width.lg;
      } else if (screenWidth >= 1095 && screenWidth < 1332 ) {
        return width.md;
      } 
      else if(screenWidth < 830){
        return width.xs;
      }
      
      else if (screenWidth < 1095 ) {
        return width.sm;
      }
      
     
      
    }
    return '100%'; 
  };

  return (
    <div>
      <Skeleton
        className={className}
        style={{ borderRadius: borderRadius , marginLeft:marginLeft,objectFit:objectFit,marginTop:marginTop }}
        variant={variant}
        height={height}
        width={getResponsiveWidth()} 
      />
    </div>
  );
};

export default Skeletonloader;
