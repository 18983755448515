import React, { useState } from 'react';
import adminLayout from '../../hoc/adminLayout';
import { Form, Button, Modal } from 'react-bootstrap';
import ApiCall from '../Constants/ApiCall';
import ReserveFront from '../Reservation/ReserveFront';
import { useNavigate, useLocation, Link, json } from "react-router-dom";
import AlertDismissible from '../Common_Fun/AlertDismissible';
import Loading from '../../images/Loading';
import RoomAllot from '../Reservation/RoomAllot';
import warn from "../../assets/images/warning.png";
import "../../assets/css/form_x.css"


const ChangeRoom = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [show, setShow] = useState(false)
    const [total, SetTotal] = useState(0);
    const [loading, setLoading] = useState(false)
    const [contentLoaded, setContentLoaded] = useState(false);
    const [buttonText, setButtonText] = useState('Add Room');
    const currentDate = new Date().toISOString().split('T')[0];
    const [roomData, setRoomData] = useState('');
    const [roomType, setRoomType] = useState('');
    const [selectRoomDict, setSelectRoomDict] = useState({})
    const [show_room, setShow_room] = useState(false)
    const [availabilityMsg, setAvailabilityMsg] = useState('')
    const [back, setBack] = useState('')
    const [sameRoomType, setSameRoomType] = useState(false);
    const [differentRoomType, setDifferentRoomType] = useState(false);
    const [data, setData] = useState();
    const [choose, setChoose] = useState("no")
    const [urlA, setUrl] = useState();
    const [limit, SetLimit] = useState(0)
    const [endD, setEndD] = useState(false)
    const [endS, setEndS] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState({})
    const [msg, setMsg] = useState('')
    const [oldRm, setOldRm] = useState({})
    const [roomStatus, setRoomStatus] = useState({})
    const [sumTotal, setSumTotal] = useState({})
    const [newselectedRoom, setNewSelectedRoom] = useState({})
    const [discountRent, setDiscountRent] = useState('')
    const [sumRent, setSumRent] = useState('')
    const [defaultRate, setDefaultRate] = useState('')
    const [dateerror,setDateerror] = useState('')
    let checkOutDate = new Date(location.state.data.checkOut);
    checkOutDate.setDate(checkOutDate.getDate() + 1);
    let newDate = checkOutDate.toISOString().slice(0, 10);
    const limitDate = new Date();
    limitDate.setDate(limitDate.getDate() + 30);
    const limitD = limitDate
    const extendStay = () => {
        setShowPopup(false)
        setContentLoaded(true)
    }
    const handleEndDateChange = (e) => {
        setEndD(false)
        const EndD = new Date(limitD)
        const curr = new Date(e.target.value)
        const selectedDate = e.target.value;
        let newMaxDate = new Date(location.state.data.checkOut);
        console.log("00000000000...", curr, EndD)
        // Check if the selected date is not before the current date
        if (curr > EndD) {
            console.log("0000000--->>", 'here')


            newMaxDate.setDate(newMaxDate.getDate() + 30);
            document.getElementById('new_check_out').setAttribute('max', newMaxDate.toISOString().split('T')[0]);
            setEndDate(newMaxDate.toISOString().split('T')[0]);
        } else if (curr < newMaxDate) {
            console.log("first", curr, limitD)
            setEndDate(newMaxDate);
        } else {
            console.log("last")
            // If selected date is before the current date, set it to the current date
            setEndDate(selectedDate);
        }
    };
    const handleStartDateChange = (e) => {
        setEndS(false)
        const EndD = new Date(limitD)
        const curr = new Date(e.target.value)
        const selectedDate = e.target.value;
        let newMaxDate = new Date(location.state.data.checkIn);
        // Check if the selected date is not before the current date
        if (curr > EndD) {
            newMaxDate.setDate(newMaxDate.getDate() + 30);
            document.getElementById('new_check_in').setAttribute('max', newMaxDate.toISOString().split('T')[0]);
            setStartDate(newMaxDate.toISOString().split('T')[0]);
        } else if (curr < newMaxDate) {
            console.log("first", curr, limitD)
            setStartDate(newMaxDate);
        } else {
            console.log("last")
            // If selected date is before the current date, set it to the current date
            setStartDate(selectedDate);
        }
    };

    console.log(startDate, endDate, 'aajjjjj')
    const [buttonStyle, setButtonStyle] = useState({
        backgroundColor: '#1AB64F',
        color: '#fff',
        width: '136px',
        height: '40px',
        border: 'none',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '19.36px',
        fontFamily: 'inter',
        marginLeft: 'auto'
    });
    const [roomCount, setRoomCount] = useState(1);

    const handleClose = () => {
        setShow(false)
        setChoose("no")
        roomdetails("different")
    }

    const roomSelect = () => {
        const copy = [...roomData]
        const totalRoom = Object.keys(selectedRoom)
        const newDict = {}
        copy.map((room) => {
            if (totalRoom.includes(room.roomType.room_all_type)) {
                if (room.roomType.room_all_type in newDict) {
                    newDict[room.roomType.room_all_type].push(room.number)
                } else {
                    newDict[room.roomType.room_all_type] = [room.number]
                }

            }
        })
        setSelectRoomDict(newDict)
        setShow(false)
        setShow_room(true)
        console.log("copy--", copy, newDict)
        // this.setState({ show: false })
        // this.setState({ show_room: true })
    }


    const handleButtonClick = () => {
        if (buttonText === 'Add Room') {
            setButtonText('Cancel');
            setButtonStyle({
                backgroundColor: '#EA4747',
                color: '#fff',
                width: '136px',
                height: '40px',
                border: 'none',
                borderRadius: '5px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '19.36px',
                fontFamily: 'inter',
                marginLeft: 'auto'
            });
        } else if (buttonText === 'Cancel') {
            setButtonText('Add Room');
            setButtonStyle({
                backgroundColor: '#1AB64F',
                color: '#fff',
                width: '136px',
                height: '40px',
                border: 'none',
                borderRadius: '5px',
                fontSize: '16px',
                fontWeight: '500',
                lineHeight: '19.36px',
                fontFamily: 'inter',
                marginLeft: 'auto'
            });
        }
        // Add logic here to handle other actions when button is clicked
    };

    const handleDecrease = () => {
        if (roomCount > 1) {
            setRoomCount(roomCount - 1);
        }
    };

    const finalSelect = (dict) => {
        console.log(dict, 'slslskakak')
        setSelectedRoom(dict)
        setNewSelectedRoom(dict)
        setShow(false)
        setChoose("yes")
        roomdetails("different", dict)
    }

    const handleIncrease = () => {
        setRoomCount(roomCount + 1);
    };

    const warning = (msg) => {
        setMsg(msg)
    }

    const msgFun = () => {
        setMsg("")
        if(dateerror !==''){
            setDateerror('')
        }
    }
    console.log(newselectedRoom, 'ahahahaha')
    const roomdetails = (type, dict = null) => {
        let newselectdict;


        if (dict === null) {
            newselectdict = selectedRoom
        }
        else {
            newselectdict = dict
        }
        console.log(dict, 'aaaaa')
        if (type === "back") {
            navigate('/')
        }
        else if (type === "different") {
            let sumV = 0
            Object.keys(newselectdict).map((key) => {
                sumV += newselectdict[key].length
            })

            if (limit !== sumV) {
                setMsg(`Select at least ${limit} rooms`)
            } else {

                navigate('/userInfoDetails', {
                    state: {
                        data: {
                            booking: data.booking.id,
                            roomStatus: roomStatus,
                            notdisableButton: true,
                            extendData: data,
                            endDate: endDate,
                            startDate:startDate,
                            url: urlA,
                            select: newselectdict,
                            choose: type,
                            guest: data.booking.guest,
                            notShow: true,
                            extend: true,
                            chooseR: choose,
                            changedate:true
                          
                        },
                    },
                });
            }
        } else {
            navigate('/userInfoDetails', {
                state: {
                    data: {
                        booking: data.booking.id,
                        notdisableButton: true,
                        extendData: data,
                        endDate: endDate,
                        startDate:startDate,
                        roomStatus: roomStatus,
                        url: urlA,
                        select: selectedRoom,
                        choose: type,
                        guest: data.booking.guest,
                        notShow: true,
                        chooseR: choose,
                        notdisableButton: true,
                        extend: true,
                        changedate:true
                      
                    },
                },
            });
        }

    }

    // const shortCon = () => {

    //    if(contentLoaded){
    //     let diScountDay = discountRent;
    //     let defaultSum = {};
    //     let sum = {};
    //     let dateVal = {};
    //     let calculate = 0;
    //     let defaultCal = 0;
    //     let rentTotal;
    //     let SelectRoom = {};
    //     let newTry = {};
    //     let totalDay = Object.keys(Object.values(diScountDay)[0]).length;
    //     Object.keys(selectedRoom).map((e) => {
    //         let totalSelect = selectedRoom[e].length;
    //         SelectRoom[e] = totalSelect;
    //         let defaultTotal =
    //         defaultRate[e] * parseInt(totalDay) * totalSelect;
    //         defaultSum[e] = defaultTotal;
    //         rentTotal = parseInt(sumRent[e]) * totalSelect;
    //         sum[e] = rentTotal;
    //         defaultCal += defaultTotal;
    //         calculate += rentTotal;
    //         console.log(e);

    //         selectedRoom[e].map((val) => {
    //             dateVal[val] = diScountDay[e];
    //             newTry[e] = diScountDay[e];
    //         });
    //     });

    //     let dict = {
    //         "room number": dateVal,
    //         "default rent sum": defaultSum,
    //         "final calculation": calculate,
    //         "default final sum": defaultCal,
    //         "discount cal": sum,
    //         "select Room": SelectRoom,
    //         "total room": total,
    //         "check-in": startDate,
    //         "check-out": endDate,
    //         // "room img": this.state.roomImg,
    //         adult: 1,
    //         child: 0,
    //         roomTypes: newTry,
    //         "extra guest":0,
    //         // We'll use a function to get it now
    //         //"extra guest": this.decideDays(),
    //         infant: 0,
    //       };
      
    //       // this data send to the next page
    //       // this.setState({ sendData: dict })
    //       return dict;
    //    }

    // }
    // console.log(shortCon(), 'jjjjjj')
    const NumBooking = (value, dict, img) => {
        SetTotal(value)
        setSelectedRoom(dict)
    }
    const handleDateError = (message) => {
       
        setTimeout(() => {
            setDateerror(message);
        }, 500); // Clears the error after 3 seconds
    }
    console.log(total, sumTotal, selectedRoom, 'skkk')
    const handleSearch = async () => {
        if (!startDate && !endDate) {
            setEndS(true)
            setEndD(true)
            return
        }
        if (!startDate) {
            setEndS(true)
            return
        }
        if (!endDate) {
            setEndD(true)
            return
        }
        if (startDate > endDate) {
            handleDateError('Start date cannot be greater than End date');
            return
        }
        if (startDate === endDate) {
            handleDateError('Start date cannot be the same as End date');
            return
        }
        if (endDate && startDate) {
            setEndD(false)
            setEndS(false)
            setContentLoaded(false)
            setShowPopup(false)
            setLoading(true)
            let body = {
                "type": "check-out",
                "endDate": endDate,
                'startDate':startDate
            }
            const booking_id = location.state.data.booking

            let property = localStorage.getItem('property');
            const api_call = await ApiCall(`${props.url}extend-booking/${property}/${booking_id}/`, 'post', body)

            setLoading(false)
            console.log("api_call", api_call)
           


            if (api_call.error === undefined) {
                if(api_call?.booking?.if_checkedin){
                    setMsg('User has already Checked In')
                    return
                }
                let discount_r = api_call.discount_rent
                let rent_ = JSON.parse(api_call.booking.room_rent)
                console.log("rent_", rent_)
                let final_dict = { ...discount_r }

                Object.keys(rent_).map((roomT) => {
                    let new_ = roomT.split("__")[0]
                    Object.keys(rent_[roomT]).map((price) => {
                        if (price in final_dict[new_]) {
                            final_dict[new_][price] = parseFloat(rent_[roomT][price])
                        }

                    })
                })
                let final_sum = {}


                Object.keys(final_dict).map((roomType) => {
                    Object.keys(final_dict[roomType]).map((day) => {
                        if (roomType in final_sum) {
                            final_sum[roomType] += final_dict[roomType][day]
                        } else {
                            final_sum[roomType] = final_dict[roomType][day]
                        }
                    })
                })
                setSumTotal(final_sum)
                console.log("----------here2")
                const arr = {}
                api_call.booking.roomNumber.map((room) => {
                    if (room.roomType.room_all_type in arr) {
                        arr[room.roomType.room_all_type].push(room.number)
                    } else {
                        arr[room.roomType.room_all_type] = [room.number]
                    }
                })
                setOldRm(arr)
                setUrl(`${props.url}extend-booking/${property}/${booking_id}/`)
                setData(api_call)
                setSameRoomType(api_call.msg.same)
                setDifferentRoomType(api_call.msg.different)
                setRoomData(api_call.rooms)
                setDiscountRent(api_call.discount_rent)
                setSumRent(api_call.sum_rent)
                let dict = {};
                api_call.room_type.map((typeRate) => {
                    dict[typeRate["room_all_type"]] = typeRate["room_rate"];
                });
                setDefaultRate(dict)
                const dictStatus = {}
                api_call.rooms.map((room) => {
                    dictStatus[room.number] = room.roomStatus
                })
                setRoomStatus(dictStatus)
                setRoomType(api_call.room_type)
                const promise = api_call.booking.promise
                let AddLimit = 0
                Object.keys(promise).map((key) => {
                    AddLimit += parseInt(promise[key])
                })
                SetLimit(AddLimit)
                if (api_call.msg.different && api_call.msg.same) {

                    setAvailabilityMsg({
                        "head": "New room number available",
                        "main": "New room numbers are available. Would you like to explore these options, or proceed with booking the same room?"
                    })
                    setBack('same')
                    setShowPopup(true);
                } else if (api_call.msg.different) {
                    setAvailabilityMsg({
                        "head": "Same room number not available",
                        "main": "The same room number is not available. Would you like to explore other options and proceed with booking a different room?"
                    })
                    setBack('back')
                    setShowPopup(true);
                }
                else if (api_call.msg.same) {
                    navigate('/userInfoDetails', {
                        state: {
                            data: {
                                booking: data.booking.id,
                                notdisableButton: true,
                                extendData: api_call,
                                endDate: endDate,
                                startDate:startDate,
                                roomStatus: dictStatus,
                                url: `${props.url}payform/${property}/${booking_id}/`,
                                choose: "same",
                                guest: data.booking.guest,
                                notShow: true,
                                chooseR: choose,
                                notdisableButton: true,
                                extend: true,
                                changedate:true
                              
                            },
                        },
                    });
                }
                else {
                    setMsg("Sorry, we don't have any available rooms for the specific date. Please select alternative dates.")
                }
            }
            else {
                setMsg(api_call.error)
            }
        }
        else {
            setEndD(true)
            setEndS(true)
        }
    }


    
    return (
        <div>

            {msg && <AlertDismissible msgFun={msgFun} type={"danger"} msg={msg} />}
            {dateerror !== '' && <AlertDismissible msgFun={msgFun} type={"danger"} msg={dateerror} />}
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group id='dob'>
                        <Form.Label>Old CheckIn Date</Form.Label>
                        <Form.Control
                            value={location.state?.data?.checkIn}
                            disabled
                            // Set the value to the present date // Disable the input field
                            type='date'
                        />
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Form.Group id='endDate'>
                        <Form.Label>Old CheckOut Date</Form.Label>
                        <div className='d-flex align-items-center'>
                            <Form.Control
                                disabled// Handle change for end date input
                                value={location.state?.data?.checkOut}
                                type='date'
                                id='check_out'
                            />
                        </div>

                    </Form.Group>
                </div>
            </div>
            <br />
            <div className='row'>
                <div className='col-md-3'>
                    <Form.Group id='dob'>
                        <Form.Label className='required'>New CheckIn Date</Form.Label>
                        <Form.Control
                            // Set the value to the present date // Disable the input field
                            onChange={handleStartDateChange} // Handle change for end date input
                            value={startDate}
                            min={newDate}
                            type='date'
                            max={limitD}
                            id='new_check_in'
                        />
                    </Form.Group>
                    {endS && <small className="text-danger ml-2">CheckIn date is required</small>}
                </div>


                <div className='col-md-3'>
                    <Form.Group id='endDate'>
                        <Form.Label className='required'>New CheckOut Date</Form.Label>
                        <div className='d-flex align-items-center'>
                            <Form.Control
                                onChange={handleEndDateChange} // Handle change for end date input
                                value={endDate}
                                min={newDate}
                                type='date'
                                max={limitD}
                                id='new_check_out'
                            />
                        </div>
                        {endD && <small className="text-danger ml-2">CheckOut date is required</small>}
                    </Form.Group>
                </div>

                <div className='col-md-3'>
                    <Button className='mt-4' variant='primary' onClick={() => handleSearch()}>
                        Search
                    </Button>
                </div>
            </div>



            {loading ? <Loading /> : <>

                <div>
                    {showPopup && (
                        <div
                            className="popup"
                            style={{
                                width: '643px',
                                height: '340px',
                                borderRadius: '5px',
                                backgroundColor: 'white',
                                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                zIndex: '9999'
                            }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', backgroundColor: '#47ADFF', color: '#fff', borderRadius: '5px' }}>
                                <h3 style={{ fontFamily: 'Inter', fontSize: '24px', fontWeight: '500', lineHeight: '29px', letterSpacing: '0em', textAlign: 'left' }}>{availabilityMsg.head}</h3>
                                <button onClick={() => setShowPopup(false)} style={{ color: '#fff', background: 'none', borderRadius: '50%', border: '1px solid white', cursor: 'pointer' }}>X</button>
                            </div>
                            <div style={{ padding: '20px' }}>
                                <p style={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: '500', lineHeight: '24.2px', letterSpacing: '0em', color: '#4B4A4A' }}>{availabilityMsg.main}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '80px', padding: '0 20px 20px 20px' }}>
                            <button style={{ width: '215px', height: '40px', border: 'none', backgroundColor: '#47ADFF', marginRight: '10px', color: '#fff', padding: '10px 20px', borderRadius: '5px', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500', lineHeight: '19.2px', letterSpacing: '0em' }} onClick={() => { extendStay() }}>Yes, Select new room</button>
                            <button id='nothanksbutton' style={{ width: '215px', height: '40px', backgroundColor: '#fff', color: '#47ADFF', padding: '10px 20px', border: '1px solid #47ADFF', borderRadius: '5px', fontFamily: 'Inter', fontSize: '16px', fontWeight: '500', lineHeight: '19.2px', letterSpacing: '0em' }} onClick={() => { roomdetails(back) }}>No, Thanks</button>

                            </div>
                        </div>
                    )}
                </div>


                {contentLoaded && (
                    <>
                        <div style={{ border: '1px solid #A9A9A9', width: '1130px', top: '248px', left: '289px', marginTop: '40px' }}>
                            {/* Content for section 1 */}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            <p style={{ marginRight: 'auto', fontSize: '24px', fontWeight: '500', lineHeight: '29.05px', color: '#D31111', fontFamily: 'inter' }}>Check out this rooms to find the ideal match for you!</p>

                            <button onClick={() => { setShow(true) }} style={{ width: '169px', height: '40px', border: 'none', borderRadius: '5px', backgroundColor: '#1AB64F', color: '#fff', fontSize: '20px', fontWeight: '500', lineHeight: '24.02px', fontFamily: 'inter' }}>
                                Book Now
                                {total > 0 && <span style={{ left: '68px', top: "-30px" }} className=" translate-middle badge rounded-pill bg-danger">
                                    {total}
                                    <span className="visually-hidden">unread messages</span>
                                </span>}
                            </button>


                            <Modal
                                className="selectNT"
                                show={show}
                                onHide={handleClose}
                                backdrop="static"
                                keyboard={false}
                            >

                                <Modal.Body className='newmodalroommove' style={{ background: "#FFF6D6", color: "#917821" }}>
                                    <img src={warn} style={{ width: 50, height: 50 }} />
                                    <div>
                                        <p>Would you prefer to select your room?</p>
                                        <button onClick={() => { roomSelect() }} style={{ marginLeft: "130px" }} className="btn">Yes</button>
                                        <button onClick={() => { handleClose() }} className="btn">No</button>
                                    </div>



                                </Modal.Body>

                            </Modal>
                        </div>

                        <RoomAllot roomStatus={roomStatus} oldRoom={oldRm} fun={true} finalSelect={finalSelect} totalRoom={selectedRoom} selectRoom={selectRoomDict}
                            setShow_room={setShow_room} show_room={show_room} />

                        {contentLoaded && <ReserveFront sumTotal={sumTotal} limit={limit} low={true} data={roomData} roomType={roomType} warning={warning} total={NumBooking} />}

                    </>
                )}
            </>}
        </div>
    );
};

export default adminLayout(ChangeRoom);