// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    font-family: Inter, sans-serif;
}

.modal-backdrop.show{
    opacity: 0.3;
}
.fade .model-extend .modal .show {
    z-index: 999 !important;
  }
  .modal  {
    z-index: 999 !important;
  }
.modal-backdrop {
    z-index: -1;
  }
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;AACA;IACI,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;AACF;IACI,WAAW;EACb","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');\n\nbody{\n    font-family: Inter, sans-serif;\n}\n\n.modal-backdrop.show{\n    opacity: 0.3;\n}\n.fade .model-extend .modal .show {\n    z-index: 999 !important;\n  }\n  .modal  {\n    z-index: 999 !important;\n  }\n.modal-backdrop {\n    z-index: -1;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
